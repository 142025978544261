<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Product Attributes"
              canSelect="none"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadProductAttributes()"
            >
              <template #columns>
                <DxColumn data-field="name" caption="Name" />
                <DxColumn data-field="description" caption="Description" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="modalShow" centered @hide="closeModal()" size="lg">
      <b-row>
        <b-col>
          <b-form-group label="Name" label-for="v-name">
            <b-form-input v-model="formData.name" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Description" label-for="v-description">
            <b-form-textarea v-model="formData.description" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Details" label-for="v-product-option-details">
            <DxDataGrid
              id="v-product-option-details"
              key-expr="id"
              :data-source="dataSourceDetails"
              :focused-row-enabled="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-borders="true"
              :row-alternation-enabled="true"
              @row-removed="handleEvent('row-removed', $event)"
            >
              <DxEditing
                :allow-updating="!isDisabled"
                :allow-deleting="!isDisabled"
                :allow-adding="!isDisabled"
                :use-icons="!isDisabled"
                mode="row"
              />

              <DxColumn type="buttons">
                <DxButton icon="edit" :onClick="onEditDetail" />
                <DxButton name="delete" />
              </DxColumn>

              <DxColumnFixing :enabled="true" />
              <DxFilterRow :visible="true" />
              <DxHeaderFilter :visible="true" />

              <DxColumn data-field="name" caption="Name" />

              <DxToolbar>
                <DxItem
                  v-if="!isDisabled"
                  location="before"
                  template="addButtonTemplate"
                />
              </DxToolbar>

              <template #addButtonTemplate>
                <div>
                  <b-button
                    :disabled="!formData.name"
                    variant="primary"
                    class="text-capitalize"
                    @click="modalShowDetail = true"
                  >
                    Add
                  </b-button>
                </div>
              </template>
            </DxDataGrid>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="closeModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          :disabled="!formData.name"
          size="sm"
          @click="onSubmitProductAttribute()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="modalShowDetail" centered @hide="closeModalDetail()">
      <b-row>
        <b-col>
          <b-form-group label="Name" label-for="v-name">
            <b-form-input v-model="formdata_detail.name" />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="closeModalDetail()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmitProductAttributeDetail()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import productAttributesApi from '@api/product_attributes';
import { queryParameters } from '@/schema';

export default {
  name: 'ProductAttributesPage',
  components: {},
  data: () => ({
    dataIndex: -1,
    isDisabled: false,
    modalShow: false,
    modalShowDetail: false,
    dataSource: [],
    dataSourceDetails: [],

    formdata_detail: {
      id: 0,
      header_id: 0,
      name: '',
      status: 'O',
    },

    formData: {
      id: 0,
      name: '',
      description: '',
      status: 'O',
    },
  }),
  mounted() {
    this.loadProductAttributes();
  },
  methods: {
    loadProductAttributes() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      productAttributesApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitProductAttribute() {
      const data = this.formData;
      data.details = this.dataSourceDetails;

      if (this.formData.id) {
        productAttributesApi
          .update(this.formData)
          .then(({ data }) => {
            this.loadProductAttributes();
            this.closeModal();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });

        return;
      }

      productAttributesApi
        .add(this.formData)
        .then(({ data }) => {
          this.loadProductAttributes();
          this.closeModal();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitProductAttributeDetail() {
      if (!this.formdata_detail.id) {
        this.formdata_detail.id = this.dataSourceDetails.length + 1;
      }

      if (this.dataIndex > -1) {
        this.dataSourceDetails.splice(this.dataIndex, 1);
        this.dataSourceDetails.splice(this.dataIndex, 0, this.formdata_detail);
      } else {
        this.dataSourceDetails.push(this.formdata_detail);
      }

      this.closeModalDetail();
    },
    onOpenModal(event) {
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      this.$nextTick(() => {
        this.formData = Object.assign({}, data);

        if (data.details.data) {
          this.dataSourceDetails = [];

          data.details.data.forEach((detail) => {
            const item = {
              id: detail.id,
              header_id: detail.header_id,
              name: detail.name,
              status: detail.status,
            };

            this.dataSourceDetails.push(item);
          });
        }
      });
    },
    onEditDetail(event) {
      this.modalShowDetail = true;
      this.$nextTick(() => {
        const data = event.row.data;
        this.dataIndex = event.row.dataIndex;

        this.formdata_detail = Object.assign({}, data);
      });
    },
    onDelete(event) {
      productAttributesApi
        .delete(event)
        .then(() => {
          this.loadProductAttributes();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.modalShow = false;
      this.dataSourceDetails = [];
      const defaultForm = {
        id: 0,
        name: '',
        description: '',
        status: 'O',
      };

      this.$nextTick(() => {
        this.formData = Object.assign({}, defaultForm);
        this.closeModalDetail();
      });
    },
    closeModalDetail() {
      this.modalShowDetail = false;
      const defaultForm = {
        id: 0,
        header_id: 0,
        name: '',
        status: 'O',
      };

      this.$nextTick(() => {
        this.formdata_detail = Object.assign({}, defaultForm);
      });
    },
    handleEvent(event) {
      //
    },
  },
};
</script>

<style></style>
