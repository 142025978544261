import api from '@apiInstance'

export default {
  add(params) {
    return api.post('productattributes_headers', params)
  },
  get(id) {
    return api.fetch('productattributes_headers/' + id)
  },
  list(params) {
    return api.fetch('/productattributes_headers', { params })
  },
  update(params) {
    return api.patch('productattributes_headers', params)
  },
  delete(id) {
    return api.remove('productattributes_headers', id)
  },
  updateProduct(id, body) {
    return api.request({
      url: `/productattributes_headers/${id}/productoptions`,
      method: 'PATCH',
      body,
      type: 'productattributes_headers'
    })
  }
}
